import { template as template_066bd6f8683343699621e764a80b9335 } from "@ember/template-compiler";
import DButton from "discourse/components/d-button";
const BackButton = template_066bd6f8683343699621e764a80b9335(`
  <DButton
    @action={{@onGoBack}}
    @label="topic.timeline.back"
    @title="topic.timeline.back_description"
    class="btn-primary btn-small back-button"
  />
`, {
    eval () {
        return eval(arguments[0]);
    }
});
export default BackButton;
