import { template as template_9100383cc8354820bdc43a1baa1b31d0 } from "@ember/template-compiler";
const FKText = template_9100383cc8354820bdc43a1baa1b31d0(`
  <p class="form-kit-text" ...attributes>
    {{yield}}
  </p>
`, {
    eval () {
        return eval(arguments[0]);
    }
});
export default FKText;
